import { useContext } from 'react';

import { ContentContext } from '../contexts/ContentContext';

import assets from '../../../assets/**/*.svg';

export default () => {
  const { content } = useContext(ContentContext);

  const strings = {
    '[TT Nyhetsbyrån](https://tt.se)': '<a href="https://tt.se">TT Nyhetsbyrån</a>',
    '%%%%COUNTER%%%%': content?.slides?.length,
    '**': ''
  };

  return (
    <footer className="about">
      <div className="about__container">
        {content?.about?.[0]?.fields?.aboutText.map(({ fields }, key) => (
          <div className="about__section" key={key}>
            {fields.headline && (
              <span className="about__heading">
                {key === 1 && <img src={assets.icons.search} />}
                {key === 3 && <img src={assets.icons.question} />}
                {key === 0 ? <h1>{fields.headline}</h1> : <h2>{fields.headline}</h2>}
              </span>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html: fields.description.replace(/%.*%|\[.*]\(.*\)|\*\*/g, (item) => strings[item])
              }}
            />
          </div>
        ))}
        <div className="about__footer">
          <div className="about__disclaimer">
            <a href="https://www.tv4.se">
              <img alt="TV4" width="56" height="56" src={assets.tv4} />
            </a>
          </div>
          <div className="about__disclaimer">
            <div>Valkompassen 2022</div>
            <a href="https://www.tv4.se">tv4.se</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
