import { useState, useEffect, useMemo, createContext } from 'react';

export const ContentContext = createContext({});

export const STORAGE_ID_ANSWERS = 'VK2022';
const STORAGE_ID_CRUCIALS = 'VK2022-crucial';

export const getSessionStorage = (val: string) => {
  try {
    return Array.from(JSON.parse(sessionStorage.getItem(val) || '[]'));
  } catch {
    return [];
  }
};

export const ContentProvider = ({ children }) => {
  const [answers, setAnswers] = useState(getSessionStorage(STORAGE_ID_ANSWERS));
  const [crucial, setCrucial] = useState(getSessionStorage(STORAGE_ID_CRUCIALS));
  const [content, setContent] = useState<Record<string, any>>({});
  const [isFinnished, setIsFinnished] = useState(false);

  //const slides = content?.slides?.sort(() => Math.random() - 0.5) || [];

  const slides = useMemo(() => content.slides || [], [content]);

  useEffect(
    () =>
      fetch('https://valkompass.tt.se/3250/data.json')
        .then((response) => response.json())
        .then((data) => setContent(data))
        .catch((e) => console.log('Could not fetch "valkompassen" data.', e)),
    []
  );

  useEffect(() => {
    try {
      sessionStorage.setItem(STORAGE_ID_ANSWERS, JSON.stringify(answers));
    } catch (e) {
      console.log('could not set storage', e);
    }
  }, [answers]);

  useEffect(() => {
    try {
      sessionStorage.setItem(STORAGE_ID_CRUCIALS, JSON.stringify(crucial));
    } catch (e) {
      console.log('could not set storage', e);
    }
  }, [crucial]);

  useEffect(() => {
    if (slides.length && answers.length === slides.length) {
      setIsFinnished(true);
    } else {
      setIsFinnished(false);
    }
  }, [answers, slides]);

  const value = {
    slides: slides,
    crucial: crucial,
    content: content,
    answers: answers,
    isFinnished: isFinnished,
    setAnswers: (data) => setAnswers(data),
    setCrucial: (data) => setCrucial(data)
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
