import { useState, createContext } from 'react';

export const VideoContext = createContext({});

export function getIsMobile() {
  const agent = window.navigator.userAgent.toLowerCase();

  return (
    /android|blackberry|windows phone|iemobile|wpdesktop|iphone|ipod|ipad/.test(agent) ||
    (/macintosh/i.test(agent) && navigator.maxTouchPoints > 1)
  );
}

export const VideoProvider = ({ children }) => {
  const [muted, setMuted] = useState(getIsMobile() ? false : true);
  const [loaded, setLoaded] = useState([]);

  const value = {
    muted: muted,
    loaded: loaded,
    setMuted: (mute) => setMuted(mute),
    setLoaded: (id) => setLoaded(id)
  };

  return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>;
};
