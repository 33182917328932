const _temp0 = require("../logotype.svg");
const _temp1 = require("../tv4.svg");
const _temp2 = require("../icons/arrow.svg");
const _temp3 = require("../icons/checkmark.svg");
const _temp4 = require("../icons/facebook.svg");
const _temp5 = require("../icons/favorite.svg");
const _temp6 = require("../icons/information.svg");
const _temp7 = require("../icons/mute.svg");
const _temp8 = require("../icons/pause.svg");
const _temp9 = require("../icons/play.svg");
const _temp10 = require("../icons/question.svg");
const _temp11 = require("../icons/restart.svg");
const _temp12 = require("../icons/search.svg");
const _temp13 = require("../icons/selected.svg");
const _temp14 = require("../icons/share.svg");
const _temp15 = require("../icons/smiley.svg");
const _temp16 = require("../icons/spinner.svg");
const _temp17 = require("../icons/unmute.svg");
const _temp18 = require("../icons/factions/background.svg");
const _temp19 = require("../icons/factions/icons/C.svg");
const _temp20 = require("../icons/factions/icons/KD.svg");
const _temp21 = require("../icons/factions/icons/L.svg");
const _temp22 = require("../icons/factions/icons/M.svg");
const _temp23 = require("../icons/factions/icons/MP.svg");
const _temp24 = require("../icons/factions/icons/S.svg");
const _temp25 = require("../icons/factions/icons/SD.svg");
const _temp26 = require("../icons/factions/icons/V.svg");

const _temp27 = require("../icons/factions/logotypes/C.svg");
const _temp28 = require("../icons/factions/logotypes/KD.svg");
const _temp29 = require("../icons/factions/logotypes/L.svg");
const _temp30 = require("../icons/factions/logotypes/M.svg");
const _temp31 = require("../icons/factions/logotypes/MP.svg");
const _temp32 = require("../icons/factions/logotypes/S.svg");
const _temp33 = require("../icons/factions/logotypes/SD.svg");
const _temp34 = require("../icons/factions/logotypes/V.svg");


const _temp35 = require("../icons/opinions/background.svg");
const _temp36 = require("../icons/opinions/welcome.svg");
const _temp37 = require("../icons/opinions/icons/0.svg");
const _temp38 = require("../icons/opinions/icons/1.svg");
const _temp39 = require("../icons/opinions/icons/2.svg");
const _temp40 = require("../icons/opinions/icons/3.svg");
const _temp41 = require("../icons/opinions/icons/4.svg");



module.exports = {
  "logotype": _temp0,
  "tv4": _temp1,
  "icons":   {
    "arrow": _temp2,
    "checkmark": _temp3,
    "facebook": _temp4,
    "favorite": _temp5,
    "information": _temp6,
    "mute": _temp7,
    "pause": _temp8,
    "play": _temp9,
    "question": _temp10,
    "restart": _temp11,
    "search": _temp12,
    "selected": _temp13,
    "share": _temp14,
    "smiley": _temp15,
    "spinner": _temp16,
    "unmute": _temp17,
    "factions":     {
      "background": _temp18,
      "icons":       {
        "C": _temp19,
        "KD": _temp20,
        "L": _temp21,
        "M": _temp22,
        "MP": _temp23,
        "S": _temp24,
        "SD": _temp25,
        "V": _temp26
      },
      "logotypes":       {
        "C": _temp27,
        "KD": _temp28,
        "L": _temp29,
        "M": _temp30,
        "MP": _temp31,
        "S": _temp32,
        "SD": _temp33,
        "V": _temp34
      }
    },
    "opinions":     {
      "background": _temp35,
      "welcome": _temp36,
      "icons":       {
        "0": _temp37,
        "1": _temp38,
        "2": _temp39,
        "3": _temp40,
        "4": _temp41
      }
    }
  }
}