import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import About from './components/About';
import Loaders from './components/Loaders';

import { VideoProvider } from './contexts/VideoContext';
import { ContentProvider } from './contexts/ContentContext';

const Home = lazy(() => import('./screens/Home', { preload: true }));
const Error = lazy(() => import('./screens/Error', { preload: true }));
const Result = lazy(() => import('./screens/Result', { preload: true }));
const Welcome = lazy(() => import('./screens/Welcome', { preload: true }));

export default () => {
  return (
    <div className="index">
      <ContentProvider>
        <VideoProvider>
          <Suspense fallback={<Loaders />}>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/home/:slide" element={<Home />} />
              <Route path="/result/:alignment" element={<Result />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
          <About />
        </VideoProvider>
      </ContentProvider>
    </div>
  );
};
